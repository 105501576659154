html {
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
/**
 * Index Page Start
 */

#services {
  a {
    color: #333;
    text-decoration: none;
  }
  a:hover {
    color: $theme-color-6;
  }
}

.content {
  background-color: $theme-color-1;
  padding: 20px;
}

h1 {
  color: $theme-color-3;
  font-size: 36px;
  font-weight: bold;
  padding: 20px 0 20px 0px;
}

p {
  padding: 0 10px;
}

.card {
  background-color: $theme-color-4;
  padding: 20px;
}

.card p {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin: 0 30px;
}

.card img {
  border-radius: 10px;
}

.card a {
  margin: 20px 0;
  background-color: $theme-color-3;
  color: $theme-color-4;
  font-size: 18px;
  font-weight: bold;
  padding: 12px 28px;
  border-radius: 5px;
}

.card a:hover {
  background-color: $theme-color-6;
  color: $theme-color-4;
}

.card h4 {
  font-size: 20px;
  font-weight: bold;
  padding: 20px 0;
}

/**
 * About Page Start
 */
.about-heading {
  padding-left: 20px;
}

.about-content p {
  font-size: 16px;
  padding: 10px 40px 0 20px;
}

.about-content a {
  width: 100%;
  background-color: $theme-color-3;
  color: $theme-color-4;
  font-size: 24px;
  font-weight: bold;
  padding: 18px 0;
  margin: 20px 0 20px 0;
  border-radius: 5px;
}

.about-content a:hover {
  background-color: $theme-color-6;
  color: $theme-color-4;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .about-content {
    a {
      width: auto;
      padding: 18px 50px;

    }
  }
}

/**
 * Services Page Start
 */
.service-content {
  background-color: $theme-color-1;
  padding: 20px;
}

.servicecard {
  background-color: $theme-color-4;
}

.servicecard a {
  font-size: 18px;
  font-weight: bold;
  padding: 5px 30px;
  margin: 10px 0;
  background-color: $theme-color-3;
  color: $theme-color-4;
}

.servicecard a:hover {
  background-color: $theme-color-6;
  color: $theme-color-4;
}

.servicecard h4 {
  font-size: 20px;
  padding: 10px 0;
  font-weight: bold;
  color: $theme-color-3;
}

.servicecard p {
  font-size: 14px;
  font-weight: bold;
}
.servicecard img {
  height: 150px;
  margin: 0 auto;
}

.support p {
  font-size: 20px;
  font-weight: bold;
}

/**
 * Training Page Start
 */
.training {
  // border-top: 2px solid #DDDDDD;

  .map-view {
    text-decoration: none;
    background-color: #fff;
    color: $theme-color-3;
    border: none;
    font-size: 24px;
    padding: 0;
  }
  .map-view:hover {
    background-color: #fff;
    color: $theme-color-6;
  }

  ul {
    padding: 0;

    li {
      list-style-type: none;
    }
  }

  .training-button {
    width: 100%;
    background-color: $theme-color-3;
    color: $theme-color-4;
    font-size: 28px;
    font-weight: bold;
    padding: 18px 0;
    margin: 20px 0 20px 0;
    border-radius: 5px;
    border: 2px solid #DDDDDD;
  }

  .training-button:hover {
    background-color: $theme-color-6;
    color: $theme-color-4;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .training {
    .training-button {
      width: auto;
      padding: 18px 60px;
    }
  }
}


/**
 * Contact Page Start
 */
.contact {
  background-color: $theme-color-1;
}

.contact-form-wrapper {
  padding-left: 20px;
}

.contact-form-wrapper label {
  font-size: 20px;
  font-weight: bold;
}

.contact-form-wrapper button {
  background-color: $theme-color-3;
  color: $theme-color-4;
  font-size: 20px;
  font-weight: bold;
  padding: 10px 30px;
  border-radius: 5px;
}

.contact-form-wrapper button:hover {
  background-color: $theme-color-6;
  color: $theme-color-4;
}

/**
 * Contact Details
 */
.contact-heading {
  padding-left: 20px;
}

#contact {
  padding: 20px 0 40px 40px;
}

#contact h4 {
  font-size: 26px;
  font-weight: bold;
}

#contact p {
  padding-left: 0;
}

#contact a {
  color: #000000;
  list-style-type: none;
  text-decoration: none;
}

#contact a:hover {
  color: $theme-color-6;
}

#contact i {
  font-size: 32px;
}

#button a {
  background-color: $theme-color-3;
  color: $theme-color-4;
}

#button a:hover {
  background-color: $theme-color-6;
  color: $theme-color-4;
}

/**
 * Product Categories
 */
#product-categories {
  h3 {
    background-color: $theme-color-3;
    color: $theme-color-4;
    padding: 18px 20px;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
  }
  ul {
    background-color: $theme-color-7;
    padding-top: 10px;

    li {
      a {
        color: #000000;
        background-color: $theme-color-7;
        font-size: 18px;
        text-decoration: none;
      }
      a:hover {
        color: $theme-color-6;
        background-color: $theme-color-7;
      }
    }
  }
  .quotation {
    padding: 10px;
    background-color: $theme-color-7;
    .btn {
      width: 100%;
      font-size: 24px;
      font-weight: bold;
      padding: 10px 0;
    }
  }
}

.free a:hover {
  background-color: $theme-color-6;
  color: $theme-color-4;
}



/**
 * Products
 */
#products {
  background-color: $theme-color-1;

  h4 {
    font-size: 18px;
    font-weight: bold;
  }
  p {
    font-size: 12px;
    font-weight: bold;
  }
  a {
    background-color: $theme-color-3;
    color: $theme-color-4;
    font-size: 16px;
    font-weight: bold;
    padding: 5px 20px;
    border-bottom: 5px;
  }
  a:hover {
    background-color: $theme-color-6;
    color: $theme-color-4;
  }
  img {
    width: 100%;
    margin: 10px auto;
    height: auto;
  }
}

.products {
  padding: 20px;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 768px) {
  #products {
    img {
      width: auto;
      height: 230px;
    }
  }
}
@media (min-width: 992px) {
  #products {
    img {
      height: 140px;
    }
  }
}


#services {
  .img-responsive {
    margin: 0 auto;
  }
}

.more-services a {
  width: 100%;
  background-color: $theme-color-3;
  color: $theme-color-4;
  font-size: 28px;
  font-weight: bold;
  padding: 18px 0;
  margin: 20px 0 20px 0;
  border-radius: 5px;
  border: 2px solid #DDDDDD;
}

.more-services a:hover {
  background-color: $theme-color-6;
  color: $theme-color-4;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .more-services a {
    width: auto;
    padding: 18px 60px;
  }
}
