#banner {
  background: url('/assets/images/Herobanner.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

.banner-wrapper {
    background: rgba(55,93,129,0.5);
 }
}

#banner h1 {
  color: $theme-color-4;
  font-weight: bold;
  font-size: 38px;
  padding: 20px 0;
 }

#banner p {
  color: $theme-color-4;
  font-size: 16px;
}

#banner a {
  width: 100%;
  background-color: $theme-color-3;
  color: $theme-color-4;
  font-size: 28px;
  font-weight: bold;
  padding: 18px 0px;
  margin: 20px 0 20px 0;
  border-radius: 10px;
  border: 2px solid #DDDDDD;
}

#banner a:hover {
  background-color: $theme-color-6;
  color: $theme-color-4;
}


// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #banner {
    h1 {
      font-size: 80px;
    }
    p {
      font-size: 22px;
    }
    a {
      width: auto;
      padding: 18px 80px;
    }
  }
}
