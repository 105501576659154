.header {

  background-color: $theme-color-1;
}

#logo {
  width: 100px;
  height: 110px;
}

#slogan {
  text-align: center;
  color: $theme-color-3;
  font-size: 22px;
  font-weight: bold;
  padding: 10px 0;
}

.icon-bar {
  background: $theme-color-4;
}
.navbar-toggle {
  margin-top: 40px;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 768px) {
  #slogan {
    text-align: right;
    font-size: 48px;
    padding: 20px;
  }
}
