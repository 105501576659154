#navigation {
  background-color: $theme-color-2;

  ul {
    background-color: $theme-color-2;

    li {
      a {
        color: $theme-color-4;
        font-weight: bold;
      }
      a:hover {
        background-color: $theme-color-3;
        border-radius: 0;
      }
    }
  }
}


.navbar-toggle {
  background: $theme-color-2;
}


// Small devices (landscape phones, 768px and up)
@media (min-width: 768px) {

  #navigation {
    height: 40px;
  }

}
