.footer {
  background-color: $theme-color-3;
  margin-top: auto;
}

.footer h2 {
  color: $theme-color-6;
  font-size: 26px;
  font-weight: bold;
}
.footer p {
  font-size: 14px;
  padding: 5px 0;
  color: $theme-color-4;
}
.footer a {
  font-size: 16px;
  font-weight: bold;
  color: $theme-color-5;
  list-style-type: none;
  text-decoration: none;
}
.footer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.footer a:hover {
  color: $theme-color-6;
}
.fontawesome_link {
  color: $theme-color-5;
  list-style-type: none;
  font-size: 32px;
}

.fontawesome_link a {
  color: $theme-color-5;
}

.fontawesome_link a:hover {
  color: $theme-color-6;
}

.footer i {
  padding: 5px 0;
  color: $theme-color-4;
  font-size: 32px;
}

.footer {
  .nav {
    li {
      a {
        padding: 0;
        &:hover {
          background: none;
          background-color: transparent!important;
          color: transparent;

          i {
            color: $theme-color-6;
          }
        }
      }
    }
  }
}

#facebook {
  font-size: 42px;
}

#twitter {
  font-size: 42px;
}

#rss {
  font-size: 42px;
}
