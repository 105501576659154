.promoted_products {
  background-color: $theme-color-2;
  padding: 20px;
}

.promotedcard {
  background-color: $theme-color-2;
  padding: 20px;
}

.promotedcard a {
  margin: 10px 0;
  background-color: $theme-color-3;
  color: $theme-color-4;
  font-size: 18px;
  font-weight: bold;
  padding: 12px 28px;
  border: 2px solid #DDDDDD;
}

.promotedcard a:hover {
  background-color: $theme-color-6;
  color: $theme-color-4;
}

.promotedcard h4 {
  font-size: 18px;
  padding: 10px 0;
  font-weight: bold;
  color: $theme-color-4;
}

.promotedcard p {
  color: $theme-color-4;
}

.promotedcard img {
  border-radius: 10px;
  height: 135px;
  margin: 0 auto;
}

.more-products a {
  width: 100%;
  background-color: $theme-color-3;
  color: $theme-color-4;
  font-size: 28px;
  font-weight: bold;
  padding: 18px 0;
  margin: 20px 0 20px 0;
  border-radius: 5px;
  border: 2px solid #DDDDDD;
}

.promoted_products a:hover {
  background-color: $theme-color-6;
  color: $theme-color-4;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .more-products a {
    width: auto;
    padding: 18px 60px;
  }
}
