$font-path: 'assests/fonts/fontawesome';
// Fontawesome
@import '../node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import '../node_modules/@fortawesome/fontawesome-free/scss/regular';
@import '../node_modules/@fortawesome/fontawesome-free/scss/solid';
@import '../node_modules/@fortawesome/fontawesome-free/scss/brands';


@import "../node_modules/bootstrap-sass/assets/stylesheets/_bootstrap.scss";

@import "variables";

@import "section/header";
@import "section/navigation";
@import "section/banner";
@import "section/content";
@import "section/promoted_products";
@import "section/footer";
@import "section/copyright";
